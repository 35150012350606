import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import styles from "./MainContent.module.css";
import CheckIcon from "@mui/icons-material/Check";
import { Grid } from "@mui/material";
import { discoverCategories, sectors } from "../utils/constants";
import StockCard from "../ui/StockCard";
import useApi from "../../useApi";
import CountrySelect from "../common/CountrySelect";
import { CompanyProfile } from "../../models/StockProfile";
import { FundType } from "../utils/fund-type.enum";
import useState from "react-usestateref";
import _ from "lodash";
function AllStockItems(searchText: any) {
  const listInnerRef = React.useRef<HTMLInputElement>(null);
  const { sendRequest, patchRequest } = useApi();
  const defaultProfiles: CompanyProfile[] = [];
  const [profiles, setProfiles, profileRef] = useState(defaultProfiles);
  const [pageTitle, setPageTitle] = useState("Discover Stocks, ETFs, Funds");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [countryList, setCountryList] = useState([]);
  const exchangeCodes = [""];
  const [selectedFilterItem, setSelectedFilterItem, filterItemRef] = useState({
    code: "US",
    label: "United States",
    exchangeCodes: exchangeCodes,
    category: "",
    sector: "",
  });
  const [prevPage, setPrevPage] = useState(0);
  useEffect(() => {
    setPageTitle("Discover Stocks, ETFs, Funds");
    fetchUserPreference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  const handleGetApiCall = async <T,>(
    path: string,
    callback: (data: T) => void
  ) => {
    try {
      const response = await sendRequest(path);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      callback(data);
    } catch (error) {}
  };
  const updateUserPreference = async <T,>(
    path: string,
    callback: (data: T) => void
  ) => {
    try {
      const response = await patchRequest(path, filterItemRef?.current?.code);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      callback(data);
    } catch (error) {}
  };
  const getCountries = (selectDefault: string) => {
    handleGetApiCall<any>("/api/stockProfiles/countryList", (data) => {
      if (data?.length > 0) {
        setCountryList(data);
        if (!selectDefault) {
          selectDefault = filterItemRef?.current?.code;
        }
        const countryInfo = data?.find((x: any) => x?.code === selectDefault);
        //  const usExchages = usInfo?.exchangeCodes;
        setSelectedFilterItem((prev) => ({
          ...prev,
          label: countryInfo.label,
          code: countryInfo.code,
          exchangeCodes: countryInfo?.exchangeCodes,
        }));
        fetchData();
      }
    });
  };
  const fetchUserPreference = async () => {
    let selectedDefault = "";
    const response = await sendRequest("/api/preferences");
    if (!response.ok) {
      selectedDefault = "true";
    } else {
      const data = await response.json();
      if (data) {
        selectedDefault = data?.country;
      }
    }
    getCountries(selectedDefault);
  };

  const fetchData = (previousRecords?: CompanyProfile[]) => {
    const fetchRecord = 20;
    if (isLoading) {
      return;
    }
    const countryCode = filterItemRef?.current?.code;
    setIsLoading(true);
    const skipRecord = prevPage * fetchRecord;
    let profileUrl = `/api/stockProfiles?country=${countryCode}&defaultImage=false&isActivelyTrading=true&$top=${fetchRecord}&$skip=${skipRecord}`;
    const selectedFund = filterItemRef?.current?.category?.toLocaleLowerCase();
    if (selectedFund) {
      const funType =
        selectedFund.indexOf("etf") > -1
          ? FundType.isETFOrFund
          : FundType.isStock;
      let qryString = "&fundType=" + funType;
      profileUrl = `${profileUrl}${qryString}`;
    }
    const selectedSector = filterItemRef?.current?.sector;
    if (selectedSector) {
      const sectoryKeyFilter = selectedSector
        ? "&sector=" + selectedSector
        : "";
      profileUrl = `${profileUrl}${sectoryKeyFilter}`;
    }
    handleGetApiCall<CompanyProfile[]>(profileUrl, (data) => {
      if (data?.length > 0) {
        data.forEach((x) => {
          x.metrics = updateMetrics(x?.metrics);
        });
      }
      if (previousRecords && previousRecords?.length > 0) {
        const resultArray = previousRecords.concat(data);
        const uniqueRecords = _.uniqBy(resultArray, "_id");
        setProfiles(uniqueRecords);
      } else {
        setProfiles(data);
      }
      setPrevPage(currentPage);
      setIsLoading(false);
    });
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const heightValue = scrollTop + clientHeight + 20;
      if (!isLoading && heightValue >= scrollHeight) {
        const currentPageValue = currentPage + 1;
        setCurrentPage(currentPageValue);
        fetchData(profiles);
      }
    }
  };
  function onSectorClick(sectorValue: string) {
    if (getSelectedSector(sectorValue)) {
      removeSectorFilter(sectorValue);
      return;
    }
    if (sectorValue) {
      setSelectedFilterItem((prev) => ({ ...prev, sector: sectorValue }));
      resetProps();
      fetchData();
    }
  }
  function onCategoryClick(categoryValue: string) {
    if (getSelectedFund(categoryValue)) {
      removeFundFilter(categoryValue);
      return;
    }
    if (categoryValue) {
      setSelectedFilterItem((prev) => ({ ...prev, category: categoryValue }));
      resetProps();
      fetchData();
    }
  }
  function onCountryChange(countryInfo: any) {
    if (countryInfo) {
      setSelectedFilterItem((prev) => ({
        ...prev,
        label: countryInfo.label,
        code: countryInfo.code,
        exchangeCodes:
          countryInfo.exchangeCodes > 0 ? countryInfo.exchangeCodes : [],
      }));
      resetProps();
      fetchData([]);
      updateUserPreference<any>(`/api/preferences`, (data) => {});
    }
  }
  function addFavouriteStockItem(stock: CompanyProfile) {
    setFavouriteProfile(stock, true);
  }
  function unFavouriteStockItem(stock: CompanyProfile) {
    setFavouriteProfile(stock, false);
  }
  const setFavouriteProfile = (stock: CompanyProfile, isFavorite: boolean) => {
    if (stock) {
      const newStateProfiles = JSON.parse(
        JSON.stringify(profiles)
      ) as CompanyProfile[];
      newStateProfiles?.forEach((x) => {
        if (x?.Symbol === stock?.Symbol) {
          x.isFavorite = isFavorite;
        }
      });
      setProfiles(newStateProfiles);
    }
  };
  const removeFundFilter = (fundValue: string) => {
    if (fundValue) {
      const clearCategory = "";
      setSelectedFilterItem((prev) => ({
        ...prev,
        category: clearCategory,
      }));
      resetProps();
      fetchData();
    }
  };
  const removeSectorFilter = (sectorValue: string) => {
    if (sectorValue) {
      const clearSector = "";
      setSelectedFilterItem((prev) => ({
        ...prev,
        sector: clearSector,
      }));
      resetProps();
      fetchData();
    }
  };
  const getSelectedSector = (sector: string) => {
    return sector === selectedFilterItem.sector;
  };

  const getSelectedFund = (fund: string) => {
    return fund === selectedFilterItem.category;
  };
  const resetProps = () => {
    setIsLoading(false);
    setCurrentPage(1);
    setPrevPage(0);
    setProfiles([]);
  };
  const updateMetrics = (metrics: any) => {
    const objKv = Object.keys(metrics);
    objKv.forEach((x) => {
      if (!metrics[x]) {
        delete metrics[x];
      }
    });
    return metrics;
  };

  return (
    <>
      <Grid container className={styles.filterSection}>
        <Grid item xs={12}>
          <h2 className={styles.heading}>{pageTitle}</h2>
          <div className={styles.pillsContainer}>
            {countryList.length > 0 && (
              <CountrySelect
                options={countryList}
                selectedCountry={selectedFilterItem}
                onChange={onCountryChange}
              ></CountrySelect>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.pillsContainer}>
            {discoverCategories.map((category) => (
              <div
                className={
                  getSelectedFund(category) ? styles.selectedPill : styles.pill
                }
                onClick={() => onCategoryClick(category)}
              >
                {getSelectedFund(category) && (
                  <CheckIcon style={{ color: "black" }} />
                )}
                {category}
              </div>
            ))}
          </div>
        </Grid>

        <Grid item xs={12} className="styles.filterTags">
          <Typography variant="body1">Filter by sectors</Typography>
          <div className={styles.pillsContainer}>
            {sectors.map((sector) => (
              <div
                className={
                  getSelectedSector(sector) ? styles.selectedPill : styles.pill
                }
                onClick={() => onSectorClick(sector)}
              >
                {getSelectedSector(sector) && (
                  <CheckIcon style={{ color: "black" }} />
                )}

                {sector}
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
      <div
        onScroll={onScroll}
        ref={listInnerRef}
        className="stock-profile-wrap"
      >
        {/* {isLoading && (
          <Box
            sx={{ display: "flex" }}
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "18px",
            }}
          >
            <CircularProgress />
          </Box>
        )} */}

        <div className="me-3">
          <Grid container spacing={2}>
            {profileRef?.current?.map((stock: CompanyProfile, i) => (
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <StockCard
                  stock={stock}
                  key={i}
                  isFavorite={stock.isFavorite}
                  addToFavouriteStock={addFavouriteStockItem}
                  unFavouriteStock={unFavouriteStockItem}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </>
  );
}

export default AllStockItems;
