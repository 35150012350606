import React from "react";
import Logo from "../../assets/logo.png";
import { Box, Dialog, DialogContent, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ApplicationRoutes } from "../../constants/application-routes";
const Disclaimer = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="my-4 d-flex justify-content-center align-items-center">
              <a href={ApplicationRoutes.Home}>
                <img src={Logo} alt="Logo" width={233} height={68} />
              </a>
            </div>
            <div className="primary-header-h5 mb-2">Disclaimer</div>
            <div className="primary-txt-14 mb-2">
              Last updated: December 29, 2022
            </div>
            <h3 className="primary-header-h3 my-4">
              Interpretation and Definitions
            </h3>
            <h4 className="primary-header-h5">Interpretation</h4>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <h4 className="primary-header-h5">Definitions</h4>
            For the purposes of this Disclaimer:
            <ul>
              <li>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Disclaimer) refers to Stockbruh, Inc
              </li>
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
              <li>
                <strong>You</strong> means the individual accessing the Service,
                or the company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </li>
              <li>
                <strong>Website</strong> refers to STOCKBRUH, accessible from{" "}
                <a
                  href="https://www.stockbruh.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {""}
                </a>
              </li>
            </ul>
            <h3 className="primary-header-h3 my-4">Disclaimer</h3>
            <p>
              The information contained on the Service is for general
              information purposes only.
            </p>
            <p>
              The Company assumes no responsibility for errors or omissions in
              the contents of the Service.
            </p>
            <p>
              In no event shall the Company be liable for any special, direct,
              indirect, consequential, or incidental damages or any damages
              whatsoever, whether in an action of contract, negligence or other
              tort, arising out of or in connection with the use of the Service
              or the contents of the Service. The Company reserves the right to
              make additions, deletions, or modifications to the contents on the
              Service at any time without prior notice. This Disclaimer has been
              created with the help of the Free Disclaimer Generator.
            </p>
            <p>
              The Company does not warrant that the Service is free of viruses
              or other harmful components.
            </p>
            <h3 className="primary-header-h3 my-4">
              External Links Disclaimer
            </h3>
            <p>
              The Service may contain links to external websites that are not
              provided or maintained by or in any way affiliated with the
              Company.
            </p>
            <p>
              Please note that the Company does not guarantee the accuracy,
              relevance, timeliness, or completeness of any information on these
              external websites.
            </p>
            <h3 className="primary-header-h3 my-4">
              Errors and Omissions Disclaimer
            </h3>
            <p>
              The information given by the Service is for general guidance on
              matters of interest only. Even if the Company takes every
              precaution to insure that the content of the Service is both
              current and accurate, errors can occur. Plus, given the changing
              nature of laws, rules and regulations, there may be delays,
              omissions or inaccuracies in the information contained on the
              Service.
            </p>
            <p>
              The Company is not responsible for any errors or omissions, or for
              the results obtained from the use of this information.
            </p>
            <h3 className="primary-header-h3 my-4">
              Views Expressed Disclaimer
            </h3>
            <p>
              The Service may contain views and opinions which are those of the
              authors and do not necessarily reflect the official policy or
              position of any other author, agency, organization, employer or
              company, including the Company.
            </p>
            <p>
              Comments published by users are their sole responsibility and the
              users will take full responsibility, liability and blame for any
              libel or litigation that results from something written in or as a
              direct result of something written in a comment. The Company is
              not liable for any comment published by users and reserves the
              right to delete any comment for any reason whatsoever.
            </p>
            <h3 className="primary-header-h3 my-4">
              No Responsibility Disclaimer
            </h3>
            <p>
              The information on the Service is provided with the understanding
              that the Company is not herein engaged in rendering legal,
              accounting, tax, or other professional advice and services. As
              such, it should not be used as a substitute for consultation with
              professional accounting, tax, legal or other competent advisers.
            </p>
            <p>
              In no event shall the Company or its suppliers be liable for any
              special, incidental, indirect, or consequential damages whatsoever
              arising out of or in connection with your access or use or
              inability to access or use the Service.
            </p>
            <h3 className="primary-header-h3 my-4">
              "Use at Your Own Risk" Disclaimer
            </h3>
            <p>
              All information in the Service is provided "as is", with no
              guarantee of completeness, accuracy, timeliness or of the results
              obtained from the use of this information, and without warranty of
              any kind, express or implied, including, but not limited to
              warranties of performance, merchantability and fitness for a
              particular purpose.
            </p>
            <p>
              The Company will not be liable to You or anyone else for any
              decision made or action taken in reliance on the information given
              by the Service or for any consequential, special or similar
              damages, even if advised of the possibility of such damages.
            </p>
            <h3 className="primary-header-h3 my-4">Contact Us</h3>
            <p>
              If you have any questions about this Disclaimer, You can contact
              Us:
            </p>
            <ul className="mb-4">
              <li>
                By email:{" "}
                <a
                  href="mailto:support@stockbruh.com"
                  rel="noopener noreferrer"
                >
                  support@stockbruh.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Grid>
        <Box component="footer" className="sb-footer my-3">
          <div style={{ color: "#ccc" }}>
            Data provided by Financial Modeling Prep
          </div>
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <a href="/privacy" style={{ color: "#ccc" }}>
                Privacy Policy
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/terms" style={{ color: "#ccc" }}>
                Terms of Use
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/" style={{ color: "#ccc" }}>
                Disclaimer
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/faq" style={{ color: "#ccc" }}>
                FAQ
              </a>
            </li>
            <li className="list-inline-item">
              <span
                className="cursor-pointer"
                style={{ color: "#ccc" }}
                onClick={handleClickOpen}
              >
                Contact Us
              </span>
            </li>
          </ul>
        </Box>
      </Grid>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth={"xs"}
        open={open}
      >
        <DialogContent dividers>
          <div className="d-flex mb-3 justify-content-between align-items-center">
            <div className="primary-header-h4">Contact Us</div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <h5 className="primary-txt-16 mb-3">
              If you have any questions, You can contact us:
            </h5>
            <ul>
              <li>
                By email:{" "}
                <a href="mailto:support@stockbruh.com">support@stockbruh.com</a>
              </li>
            </ul>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default Disclaimer;
