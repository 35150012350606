import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {SbAccordionProps} from './types'
import { Stack } from '@mui/material';

const SbAccordion: React.FC<SbAccordionProps> = ({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <Accordion className="sb-accordion linear-variant-1 mb-2" disableGutters={true} key={index}>
          <AccordionSummary 
          expandIcon={<KeyboardArrowDownIcon />}
          // expandIcon={<ExpandMoreIcon />}
          sx={{
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-expandIconWrapper': {
              // order: -1,
              marginRight: '0.5rem',
              color: '#000'
            },
          }}
          >
          <Stack direction="row" className="w-100" justifyContent="space-between" alignItems="center" spacing={2}>
              <div>
                  <Typography>{item.title}</Typography>
              </div>
              <div>
              {item.icons.map((icon, idx) => (
                <span key={idx}>
                  {icon}
                </span>
              ))}
              </div>
          </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {item.content}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default SbAccordion;
