import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultLayoutView from "../../components/common/defaultLayoutView";
import { ApplicationRoutes } from "../../constants/application-routes";

function Stock() {
  const navigate = useNavigate();
  useEffect(() => {
    const errorHandler = (event: any) => {
      navigate(ApplicationRoutes.Error);
      window.location.reload();
    };
    window.addEventListener("error", errorHandler);
    window.addEventListener("unhandledrejection", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
      window.removeEventListener("unhandledrejection", errorHandler);
    };
  });
  return <DefaultLayoutView />;
}

export default Stock;
