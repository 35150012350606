import { useCallback, useEffect, useState } from "react";
import { ChartToolTip } from "./model/chart-tooltip-change.model";
import timeMachineImageUrl from "../../images/TimeMachine.png";
import appUtils from "../../shared/utils";
import { Grid, TextField, Typography } from "@mui/material";

export const TimeMachine = ({
  currentStockPrice = 0,
  chartToolTip = { formattedValue: "0", label: new Date().toDateString() },
  currency = "USD",
}: {
  currentStockPrice: number;
  chartToolTip: ChartToolTip;
  currency: string;
}) => {
  const {
    formatToTwoDecimal,
    getInvesmentValueGrowthByPercentage,
    getInvesmentValueGrowthByDate,
  } = appUtils();

  const [investmentAmount, setInvestmentAmount] = useState<number>(1000);
  const [growthValue, setGrowthValue] = useState<number>(0);
  const [growthInPercentage, setGrowthInPercentage] = useState<number>(0);

  const formateChatDateLable = (dateLable: string) => {
    if (dateLable?.split(",")?.length > 1) {
      return new Date(dateLable);
    } else {
      return new Date();
    }
  };

  const processTimeMachine = useCallback(() => {
    const investedOn: Date = formateChatDateLable(chartToolTip?.label);
    const investedAmount: number =
      Number.parseFloat(chartToolTip?.formattedValue) || 0;

    const priceonDate = getInvesmentValueGrowthByDate(
      investmentAmount,
      investedAmount,
      currentStockPrice
    );

    const CAGR = getInvesmentValueGrowthByPercentage(
      investedOn,
      investedAmount,
      currentStockPrice
    );

    setGrowthInPercentage(CAGR);
    setGrowthValue(priceonDate);
  }, [
    chartToolTip,
    currentStockPrice,
    getInvesmentValueGrowthByDate,
    getInvesmentValueGrowthByPercentage,
    investmentAmount,
  ]);

  useEffect(() => {
    processTimeMachine();
  }, [processTimeMachine]);

  const handleChange = ($event: any) => {
    const inputValue = $event.target.value;

    // Ensure value does not exceed 10 billion
    if (inputValue === "" || Number(inputValue) <= 10000000000) {
      setInvestmentAmount(parseFloat(inputValue));
    }
  };

  return (
    <Grid container style={{ marginTop: "8rem" }}>
      <Grid item xs={12}>
        <Typography>
          <img
            src={timeMachineImageUrl}
            alt=""
            width="50"
            height="50"
            style={{ marginRight: "10px" }}
          />
          Time Machine {currency}
          <span className="stock-value-txt mx-3">
            <TextField
              type="number"
              value={investmentAmount}
              onChange={handleChange}
              placeholder="Enter a number up to 10 billion"
              InputProps={{
                inputProps: {
                  max: 10000000000, // Set max limit to 10 billion
                },
              }}
            />
          </span>
          invested on
          <span className="px-2 tm-invested-on">
            {formateChatDateLable(chartToolTip?.label).toDateString()}
          </span>
          would now be worth
          <span
            className={` px-2 tm-stock-growth ${
              growthInPercentage < 0 ? "negative" : ""
            }`}
          >
            {`${currency}  ${formatToTwoDecimal(
              growthValue
            )} (${formatToTwoDecimal(growthInPercentage)} %)`}
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};
