import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import appUtils from "../../../shared/utils";

ChartJS.register(ArcElement, Tooltip, Legend);

const SbPieChart = ({ data, options, width, height }) => {
  const { isValidChartDataSource } = appUtils();
  return !isValidChartDataSource(data) ? (
    <div></div>
  ) : (
    <div style={{ width: width || "100%", height: height || "100%" }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default SbPieChart;
