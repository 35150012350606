import React from "react";
import StockBruhDrawer from "../common/stockbruh-drawer";

interface DefaultLayoutViewProps {}

const DefaultLayoutView: React.FC<DefaultLayoutViewProps> = (props) => {
  return <StockBruhDrawer />;
};

export default DefaultLayoutView;
