import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CountryFlagIcon from "./CountryFlagIcon";

export default function CountrySelect(countryProps: any) {
  return (
    <Autocomplete
      id="sb-country-select"
      sx={{ width: 300 }}
      options={countryProps.options}
      autoHighlight
      getOptionLabel={(option: any) => option.label}
      onChange={(_event: any, newValue: string | null) => {
        countryProps.onChange(newValue);
      }}
      defaultValue={countryProps.selectedCountry}
      renderOption={(props, option: any) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <CountryFlagIcon
            imageWidth={20}
            code={option.code?.toLowerCase()}
          ></CountryFlagIcon>
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose your country"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
