import { useState } from "react";
import styles from "./MainContent.module.css";
import { Grid } from "@mui/material";
import MyFavourites from "./myFavourites";
import AllStockItems from "./allStockItems";
import StockProfileLayout from "../stockProfileDetail/stockProfileLayout";
const MainContent = () => {
  const [searchValue] = useState("");
  const getPathMatch = (pathName: string) => {
    return window.location.pathname.indexOf(pathName) > -1;
  };
  const isProfileDetails = () => {
    const isDashboardOrFav = window.location.pathname.indexOf("profile") > -1;
    return isDashboardOrFav;
  };
  return (
    <>
      <Grid container className={styles.mainSection}>
        {/* {showMenu && (
          <Grid item md={1}>
            <MenuIcon />
          </Grid>
        )} */}
      </Grid>
      {getPathMatch("favorites") && (
        <MyFavourites searchText={searchValue}></MyFavourites>
      )}
      {getPathMatch("home") && (
        <AllStockItems searchText={searchValue}></AllStockItems>
      )}
      {isProfileDetails() && <StockProfileLayout></StockProfileLayout>}
    </>
  );
};

export default MainContent;
