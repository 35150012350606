import countryToCurrency, { Countries } from "country-to-currency";
import { CompanyProfile } from "../models/StockProfile";
const appUtils = () => {
  const currencyByCountryCode = (countryCode: any) => {
    const currencyResult = countryToCurrency[countryCode as Countries];
    return currencyResult ? " " + currencyResult + " " : "";
  };
  const formatToTwoDecimal = (dataValue: number) => {
    if (isNaN(dataValue)) {
      return "";
    } else if (dataValue === 0) {
      return " " + 0 + " ";
    }
    return " " + dataValue?.toFixed(2) + " ";
  };
  const getNumber = (sciNotation: number) => Number(sciNotation);
  const calculateCAGR = (begin: number, final: number, years: number) => {
    if (begin !== 0) {
      if (begin > 0 && final >= 0)
        return Math.pow(final / begin, 1 / years) - 1;

      if (begin < 0 && final <= 0)
        return (
          -1 * (Math.pow(Math.abs(final) / Math.abs(begin), 1 / years) - 1)
        );

      if (begin < 0 && final > 0)
        return (
          Math.pow((final + 2 * Math.abs(begin)) / Math.abs(begin), 1 / years) -
          1
        );

      if (begin > 0 && final < 0)
        return (
          -1 * (Math.pow((Math.abs(final) + 2 * begin) / begin, 1 / years) - 1)
        );
    }
    return 0;
  };

  const checkIfGivenDateGreaterThanOneYear = (
    stockProfileInfo: CompanyProfile
  ) => {
    if (stockProfileInfo.stockDividend[0].date) {
      const otherDate: Date = new Date(stockProfileInfo.stockDividend[0].date);
      const today: Date = new Date();
      const differenceMs: number = today.getTime() - otherDate.getTime();
      const differenceYears: number =
        differenceMs / (1000 * 60 * 60 * 24 * 365); // assumes a year has 365 days

      if (differenceYears > 1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const findMillionBillionTrillion = (num: number) => {
    if (num >= 1000000000000) {
      return "Trillion";
    } else if (num >= 1000000000) {
      return "Billion";
    } else if (num >= 1000000) {
      return "Million";
    }
    return null;
  };
  const convertToInternationalCurrencySystem = (labelValue: number) => {
    return Math.abs(Number(labelValue)) >= 1.0e12
      ? (Number(labelValue) / 1.0e12).toFixed(2) + " T "
      : Math.abs(Number(labelValue)) >= 1.0e9
      ? (Number(labelValue) / 1.0e9).toFixed(2) + " B "
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Number(labelValue) / 1.0e6).toFixed(2) + " M "
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Number(labelValue) / 1.0e3).toFixed(2) + " K "
      : Number(labelValue);
  };
  const stockIconToolTip = (stockProfileInfo: CompanyProfile) => {
    return (
      stockProfileInfo?.companyName +
      "is a " +
      findMillionBillionTrillion(stockProfileInfo?.MktCap) +
      "dollar company with a market capitalization of " +
      stockProfileInfo?.currency +
      " " +
      convertToInternationalCurrencySystem(stockProfileInfo?.MktCap)
    );
  };
  const getYear = (before: number) => {
    const d = new Date();
    let year = d.getFullYear();
    return year - before;
  };
  const nullNumberHandler = (prop?: number) => {
    return prop ? prop : 0;
  };

  const getInvesmentValueGrowthByPercentage = (
    growDate: Date,
    growthValue: number,
    currentStockPrice: number
  ) => {
    const numberOfYears = calculateDurationInYear(growDate, new Date());
    const beginStockPrice = growthValue || 0;
    const endingStockPrice = currentStockPrice || 0;

    if (numberOfYears < 1) {
      // Calculate regular growth percentage if duration is less than 1 year
      const growthPercentage =
        ((endingStockPrice - beginStockPrice) / beginStockPrice) * 100;
      return !growthPercentage || growthPercentage === Infinity
        ? 0
        : growthPercentage;
    } else {
      // Calculate CAGR if duration is 1 year or more
      const powerOf = 1 / numberOfYears;
      const cagr =
        (Math.pow(endingStockPrice / beginStockPrice, powerOf) - 1) * 100;
      return !cagr || cagr === Infinity ? 0 : cagr;
    }
  };

  const getInvesmentValueGrowthByDate = (
    investmentAmount: number,
    growthValue: number,
    currentPrice: number
  ): number => {
    const result = (investmentAmount / growthValue) * currentPrice;

    return !result || result === Infinity ? 0 : result;
  };

  const calculateDurationInYear = (fromdate: Date, toDate: Date): number => {
    const diffInTime = toDate.getTime() - fromdate.getTime();
    const days: number = Math.round(diffInTime / (1000 * 3600 * 24));
    return days / 365;
  };
  const isValidChartDataSource = (dataSource: any) => {
    return (
      dataSource?.datasets?.length > 0 &&
      Object.keys(dataSource?.labels)?.length > 0
    );
  };
  return {
    currencyByCountryCode,
    formatToTwoDecimal,
    calculateCAGR,
    getNumber,
    checkIfGivenDateGreaterThanOneYear,
    findMillionBillionTrillion,
    convertToInternationalCurrencySystem,
    stockIconToolTip,
    getYear,
    nullNumberHandler,
    getInvesmentValueGrowthByDate,
    getInvesmentValueGrowthByPercentage,
    isValidChartDataSource,
  };
};

export default appUtils;
