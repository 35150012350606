import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import appRouteHelper from "../utils/app-route-helper";
import { Card, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import {
  MetricsDescription,
  MetricsType,
  WeatherType,
} from "../utils/constants";
export default function StockPeers(props: any) {
  const peersList = props?.peers as any[];
  const navigate = useNavigate();
  const { getDetailRoute } = appRouteHelper();
  const onPeerClick = (symbol: string) => {
    const url = getDetailRoute(symbol);
    navigate(url);
  };
  const etfStockSlider = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    arrows: true,
    slidesToShow: 6,
    speed: 500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renterPerformace = (peersMetrics: any) => {
    let metrics: MetricsType[] = [
      "returns",
      "profitability",
      "growth",
      "cash",
      "earnings",
    ];
    const defaultWeather = WeatherType.thunderstorm;
    return (
      <>
        <div className="text-center">
          <ul className="list-inline mb-0">
            {metrics.map((metric: MetricsType, i) => (
              <li className="list-inline-item">
                <Tooltip title={MetricsDescription[metric]} arrow>
                  {peersMetrics && (
                    <img
                      src={`../images/Metrics_${
                        peersMetrics[metric] || defaultWeather
                      }.png`}
                      alt={MetricsDescription[metric]}
                      width="24"
                      height="24"
                    />
                  )}
                </Tooltip>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };
  return (
    <>
      {peersList?.length > 0 && (
        <Grid container justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography variant="h5" className="mb-3">
              Peers include ...
            </Typography>
            <div className="slider-container variant-2">
              <Slider {...etfStockSlider}>
                {peersList?.map((item, index) => (
                  <div
                    className="h-100"
                    key={index}
                    onClick={() => onPeerClick(item.symbol)}
                  >
                    <Card sx={{ m: 1 }}>
                      <div className="mx-auto p-3 d-flex justify-content-center">
                        <img
                          src={item?.imgSrc}
                          alt={item?.name}
                          className="object-fit-contain"
                          width={64}
                          height={64}
                        />
                      </div>
                      <CardContent>
                        <div className="text-center">
                          <div className="primary-header-h6 text-truncate mb-1">
                            <Tooltip title={item?.name} arrow>
                              {item?.name}
                            </Tooltip>
                          </div>
                        </div>
                        {renterPerformace(item?.metrics)}
                      </CardContent>
                    </Card>
                  </div>
                ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}
