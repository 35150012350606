// import companyprofile from "../../images/Amazon_icon.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "@mui/material/Tooltip";
import sunShineIcon from "../../images/Metrics_Sunshine.png";
import cloudyIcon from "../../images/Metrics_Cloudy.png";
import thunderStromIcon from "../../images/Metrics_Thunderstorm.png";
import { CompanyProfile } from "../../models/StockProfile";
import SbAccordion from "../common/accordion/SbAccordion";
import appUtils from "../../shared/utils";
import chartHelpers from "../utils/chart-helpers";
import SbBarChart from "../common/charts/sbBarChart";
import SbPieChart from "../common/charts/sbPieChart";
import { WeatherType } from "../utils/constants";
const EtfStockProfileAccordians = (props: any) => {
  const stockProfileInfo = props?.stock as CompanyProfile;
  // eslint-disable-next-line
  const {
    createEtfDividendChart,
    createEtfSectorWeightingsChart,
    etfDividendOptions,
    etfSectorOptions,
  } = chartHelpers();
  const { formatToTwoDecimal, getYear, checkIfGivenDateGreaterThanOneYear } =
    appUtils();

  const dividendChart = () => {
    if (stockProfileInfo?.stockDividend?.length === 0) {
      return <div></div>;
    }
    const chartData = createEtfDividendChart(stockProfileInfo?.stockDividend);
    return (
      <SbBarChart
        data={chartData}
        options={etfDividendOptions()}
        width="100%"
        height="400px"
      />
    );
  };
  const etfSectorWeightChart = () => {
    if (stockProfileInfo?.sectorWeightings?.length === 0) {
      return <div></div>;
    }
    const sectorWeightings = stockProfileInfo.sectorWeightings || [];
    const chartData = createEtfSectorWeightingsChart(sectorWeightings);
    return (
      <SbPieChart
        data={chartData}
        options={etfSectorOptions()}
        width="100%"
        height="400px"
      />
    );
  };
  // Accordion Data
  const stockLongTermReturnAccordianTitle = () => {
    if (stockProfileInfo?.metrics?.returns === WeatherType.thunderstorm) {
      return (
        <Tooltip
          title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
          arrow
        >
          <img src={thunderStromIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.returns === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
        <img src={sunShineIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };
  const stockDividendAccordianTitle = () => {
    if (stockProfileInfo?.metrics?.dividend === WeatherType.sunny) {
      return (
        <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
          <img src={sunShineIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.dividend === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
        arrow
      >
        <img src={thunderStromIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };

  const etfChargeAccordianTitle = () => {
    if (stockProfileInfo?.metrics.expense === WeatherType.thunderstorm) {
      return (
        <Tooltip
          title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
          arrow
        >
          <img src={thunderStromIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics.expense === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
        <img src={sunShineIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };
  const stockReturnsContent = () => {
    if (stockProfileInfo?.priceCAGR !== 0) {
      return (
        <div>
          <h5 className="primary-header-h5">Stock Price</h5>
          <ul>
            <li>
              {stockProfileInfo.companyName}'s stock price
              {stockProfileInfo?.priceCAGR > 0 ? "grew" : "dropped"} on an
              average of
              <span
                className={
                  stockProfileInfo?.priceCAGR > 0
                    ? "stock-profit-txt"
                    : "stock-loss-txt"
                }
              >
                {formatToTwoDecimal(stockProfileInfo?.priceCAGR)}%
              </span>
              year on year for the past 5 years which means a{" "}
              {stockProfileInfo?.currency} 1000 invested in{" "}
              {stockProfileInfo.companyName} in {getYear(5)} would have
              {stockProfileInfo?.priceCAGR > 0 ? "grown" : "dropped"} to{" "}
              <strong>
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(
                  (1000 / stockProfileInfo?.prices?.begin?.close) *
                    stockProfileInfo?.prices?.end?.close
                )}
              </strong>{" "}
              today
            </li>
          </ul>
        </div>
      );
    }
    return <div className="primary-header-h3"> Oops! No Data Available </div>;
  };
  const stockDividentContent = () => {
    if (stockProfileInfo?.LastDiv > 0) {
      return (
        <div>
          <ul>
            <span>
              <ul>
                {stockProfileInfo?.LastDiv < 0 &&
                  checkIfGivenDateGreaterThanOneYear(stockProfileInfo) && (
                    <span className="primary-header-h5">
                      Tough luck! They don’t pay dividends
                    </span>
                  )}
                {stockProfileInfo?.LastDiv > 0 &&
                  stockProfileInfo?.dividendYielPercentageTTM > 0 && (
                    <span>
                      {" "}
                      <h5 className="primary-header-h5">Yes it does!</h5>
                      <li>
                        {stockProfileInfo.companyName} last gave an annual{" "}
                        dividend of{" "}
                        <span className="stock-profit-txt">
                          {stockProfileInfo?.currency}{" "}
                          {formatToTwoDecimal(stockProfileInfo?.LastDiv)}
                        </span>
                        for every stock at
                        {formatToTwoDecimal(
                          stockProfileInfo?.dividendYielPercentageTTM
                        )}
                        % of its price
                      </li>
                    </span>
                  )}
              </ul>
            </span>
          </ul>
          {dividendChart()}
        </div>
      );
    }
    return <div className="stock-value-txt"> Oops! No Data Available </div>;
  };
  const etfChargeFee = () => {
    return (
      <span style={{ textAlign: "justify" }}>
        {" "}
        Called as expense ratios, these fees are expressed in percentages and
        charged to investors to cover a fund or ETF's operating costs.
        {stockProfileInfo?.companyName} charges an expense ratio of{" "}
        <strong>{formatToTwoDecimal(stockProfileInfo?.expenseRatio)} % </strong>
        which means for a {stockProfileInfo?.currency} 1000 of the share, you
        will be charged a fees of{" "}
        <strong>
          {stockProfileInfo?.currency}{" "}
          {formatToTwoDecimal((1000 * stockProfileInfo?.expenseRatio) / 100)}
        </strong>
      </span>
    );
  };

  const etfExposure = () => {
    return (
      <div className="div-shadow">
        <ul>
          <span>
            {" "}
            The ETF comprises of stock from different sectors and the below
            chart provides a break-up of the ETF’s exposure across different
            sectors
          </span>
        </ul>{" "}
        {etfSectorWeightChart()}
      </div>
    );
  };
  const accordionItems = [
    {
      title: "Is the Etf giving long term returns?",
      content: stockReturnsContent(),
      icons: [stockLongTermReturnAccordianTitle()],
    },
    {
      title: "Does the ETF give Dividends?",
      content: stockDividentContent(),
      icons: [stockDividendAccordianTitle()],
    },
    {
      title: "What does the ETF charge you in fees?",
      content: etfChargeFee(),
      icons: [etfChargeAccordianTitle()],
    },
  ];

  return (
    <div>
      <SbAccordion items={accordionItems} />
      <div className="div-shadow">
        <h5>ETF’s exposure across sectors</h5>
        {etfExposure()}
      </div>
    </div>
  );
};

export default EtfStockProfileAccordians;
