import Logo from "../../images/logo-transparent.svg";
import Dinosaur from "../../images/dinosaur-bg.png";
import { Container, Grid } from "@mui/material";
const ErrorPage = () => {
  return (
    <div className="sb-landing-wrap">
      <Grid container>
        <Grid item md={12} sm={12}>
          <Container>
            <Grid item md={12} sm={12}>
              <div className="landing-logo">
                <img src={Logo} alt="logo" width={200} height={40} />
              </div>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={6} sm={12}>
                <div className="d-flex justify-content-center flex-column gap-2 text-center align-items-center">
                  <img src={Dinosaur} alt="dinosaur" width={320} height={320} />
                  <div className="sb-section-header text-white">Oops!</div>
                  <div className="sb-section-sub-header light-txt line-height-32 mb-3">
                    It looks like 'Errorosaurus' has chomped through some wires
                    in our code.
                  </div>
                  <div className="media-header-txt-16 light-txt">
                    <p className="mb-0">
                      Please hang tight while we track him down.
                    </p>
                    <p className="mb-0">
                      Check back again soon—we should have everything sorted out
                      by then!
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};
export default ErrorPage;
