export enum ApplicationRoutes {
  Default = "/",
  Home = "/home",
  Favorites = "/favorites",
  Profile = "/profile/:symbol",
  Privacy = "/privacy",
  Terms = "/terms",
  Disclaimer = "/disclaimer",
  Faq = "/faq",
  Error="/error"
}
