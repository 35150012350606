import { Card, CardContent, Typography, Tooltip } from "@mui/material";
import styles from "./StockCard.module.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  MetricsDescription,
  MetricsType,
  WeatherType,
} from "../utils/constants";
import { CompanyProfile } from "../../models/StockProfile";
import CountryFlagIcon from "../common/CountryFlagIcon";
import appRouteHelper from "../utils/app-route-helper";
import { useNavigate } from "react-router-dom";
import AddRemoveFavourite from "../common/addRemoveFavourite";
function StockCard(props: any) {
  const navigate = useNavigate();
  const { getDetailRoute } = appRouteHelper();
  let stock: any = props?.stock;
  const addStockToFavourite = (stock: CompanyProfile) => {
    props?.addToFavouriteStock(stock);
  };
  const removeStockFromFavourite = (stock: CompanyProfile) => {
    props?.unFavouriteStock(stock);
  };
  const redirectToDetails = (stock: CompanyProfile) => {
    const url = getDetailRoute(stock?.Symbol);
    navigate(url);
  };

  const renterPerformace = () => {
    let metrics: MetricsType[];
    const isEtf: boolean = stock.isEtf || stock.isFund;
    const defaultWeather = WeatherType.thunderstorm;
    if (isEtf) {
      metrics = ["returns", "dividend", "expense"];
    } else {
      metrics = ["returns", "profitability", "growth", "cash", "earnings"];
    }

    return (
      <>
        {metrics.map((metric: MetricsType, i) => (
          <div className={styles.performanceItem} key={metric}>
            <p className="primary-header-h6 fw-700 mb-2">
              {MetricsDescription[metric]}
            </p>
            <div>
              {stock.metrics && (
                <img
                  src={`images/Metrics_${
                    stock.metrics[metric] || defaultWeather
                  }.png`}
                  className={styles.performanceImage}
                  alt={`${stock.metrics[metric]}`}
                />
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="stock-card-wrap h-100" key={stock._id}>
      <Card className="sb-card h-100 cursor-pointer" key={"cnt_" + stock._id}>
        <div
          className="stock-profile-info"
          key={"th_" + stock._id}
          onClick={() => redirectToDetails(stock)}
        >
          <div
            className="d-flex align-items-center"
            key={"th_img_" + stock._id}
          >
            <div className="flex-shrink-0">
              <CountryFlagIcon
                code={stock?.country?.toLowerCase()}
              ></CountryFlagIcon>
            </div>
            <div className="flex-grow-1 ms-2">
              <Tooltip title={stock.companyName} arrow>
                <div className="stock-company-header">{stock.companyName}</div>
              </Tooltip>
            </div>
            <div className="d-inline-flex align-items-center gap-1">
              <span className="stock-market-header">
                {stock.exchangeShortName}
              </span>
              <span>:</span>
              <span className="stock-market-header stock-highlight">
                {stock.Symbol}
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center my-3">
            <span className="stock-profile-logo">
              <img src={stock.image} alt={stock.Symbol} className="img-fluid" />
            </span>
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          onClick={() => redirectToDetails(stock)}
        >
          <div className="stock-price-info">
            <ul className="list-inline mb-1">
              <li className="list-inline-item">
                <span className="stock-price">{stock.Price}</span>
              </li>
              <li className="list-inline-item">
                <span className="stock-currency">{stock.currency}</span>
              </li>
            </ul>
            <span
              className={
                stock.Changes > 0
                  ? `${styles.incrementFigures} ${styles.green}`
                  : `${styles.incrementFigures} ${styles.red}`
              }
            >
              {stock.Changes > 0 ? (
                <ArrowUpwardIcon className={styles.green} />
              ) : (
                <ArrowDownwardIcon className={styles.red} />
              )}
              ({stock.ChangesPercentage}%)
            </span>
          </div>
        </div>
        <CardContent>
          <div
            className="d-flex align-items-center text-center justify-content-center gap-2"
            onClick={() => redirectToDetails(stock)}
          >
            {renterPerformace()}
          </div>
          <div
            className={styles.details}
            onClick={() => redirectToDetails(stock)}
          >
            <Typography variant="body1">{stock.description}</Typography>
          </div>
          <div className="d-flex align-items-center justify-content-center mt-2">
            <AddRemoveFavourite
              stock={stock}
              backgroundColor={"#edefff"}
              addToFavouriteStock={addStockToFavourite}
              unFavouriteStock={removeStockFromFavourite}
            ></AddRemoveFavourite>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default StockCard;
