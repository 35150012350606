import React, { createContext, useState, useContext, ReactNode } from "react";

interface LoadingContextProps {
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
  increaseLoadingCount: () => void;
  decreaseLoadingCount: () => void;
  loadingCount: number;
}

const LoadingContext = createContext<LoadingContextProps | undefined>(
  undefined
);

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [loadingCount, setLoadingCount] = useState<number>(0);

  const increaseLoadingCount = () => {
    setLoadingCount(loadingCount + 1);
  };

  const decreaseLoadingCount = () => {
    const count = loadingCount - 1;
    setLoadingCount(count < 0 ? 0 : count);
  };

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        loadingCount,
        setLoading,
        increaseLoadingCount,
        decreaseLoadingCount,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};
