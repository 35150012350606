import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import appUtils from "../../../shared/utils";
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const SbBarChart = ({ data, options, width, height }) => {
  const { isValidChartDataSource } = appUtils();
  if (!isValidChartDataSource(data)) {
    return <div></div>;
  }
  return (
    <div style={{ width: width || "100%", height: height || "100%" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default SbBarChart;
