import { useState } from "react";
import styles from "../ui/StockCard.module.css";
import DraggableDialog from "./customDialog";
import useApi from "../../useApi";
import { toast } from "react-toastify";
import { CompanyProfile } from "../../models/StockProfile";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import { FavoriteRounded } from "@mui/icons-material";
import React from "react";
function AddRemoveFavourite(props: any) {
  //props details: stock,addToFavouriteStock,unFavouriteStock
  //eslint-disable-next-line
  const { patchRequest, deleteRequest } = useApi();
  const stock: any = props?.stock as CompanyProfile;
  const [removeFav, setRemoveFav] = useState({ remove: false });
  const doFavourite = (event: React.MouseEvent) => {
    setRemoveFav({ remove: false });
    event.stopPropagation();
    if (isFavouriteStock()) {
      setTimeout(() => {
        setRemoveFav({ remove: true });
      }, 2000);
      return;
    }
    const handelPatchApiCall = async <T,>(
      path: string,
      body: any,
      callback: (data: T) => void
    ) => {
      try {
        const response = await patchRequest(path, body);
        if (response.status === 400) {
          toast.info(
            "You've hit your 20 favorite stocks limit! Let go of a few to make room for new favorites",
            {
              icon: () => <HeartBrokenIcon style={{ color: "red" }} />,
            }
          );
          throw new Error(response.statusText);
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        callback(data);
      } catch (error) {}
    };
    handelPatchApiCall<CompanyProfile>(
      "/api/Favorites",
      stock.Symbol,
      (data) => {
        if (data) {
          toast.success("Stock added to favourite");
          props?.addToFavouriteStock(stock);
        }
      }
    );
  };
  const cancelClick = () => {
    setRemoveFav({ remove: false });
  };
  const isFavouriteStock = () => {
    return stock.isFavorite ? true : props?.isFavorite;
  };
  const okClick = () => {
    setRemoveFav({ remove: false });
    if (!stock) {
      return;
    }
    const handelDeleteApiCall = async <T,>(
      path: string,
      callback: (data: T) => void
    ) => {
      try {
        const response = await deleteRequest(path);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        callback(data);
      } catch (error) {}
    };
    handelDeleteApiCall<CompanyProfile>(
      "/api/Favorites?companyProfile=" + stock?.Symbol,
      (data) => {
        if (data) {
          toast.success("Stock removed from favourites");
          props?.unFavouriteStock(stock);
        }
      }
    );
  };
  return (
    <>
      {" "}
      <div
        className={styles.favourite}
        style={{ backgroundColor: props?.backgroundColor }}
        onClick={(event) => doFavourite(event)}
      >
        <span>
          <FavoriteRounded
            className={isFavouriteStock() ? styles.redIcon : styles.greyIcon}
          />
        </span>
      </div>
      {removeFav.remove && (
        <DraggableDialog
          okClick={okClick}
          cancelClick={cancelClick}
          text={"Are you sure you want to remove this from your favorites? "}
          okText={"Yes"}
          cancelText={"No"}
          icon={<HeartBrokenIcon style={{ color: "red" }} />}
        ></DraggableDialog>
      )}
    </>
  );
}

export default AddRemoveFavourite;
