import { CardHeader, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function CustomCard(props: any) {
  const openUrlInWindow = () => {
    window.open(props?.newsLink, "_blank");
  };
  return (
    <Card
      sx={{ maxWidth: props?.maxWidth, m: 1, height: '100%' }}
      onClick={openUrlInWindow}
      style={{ cursor: "pointer" }}
    >
      <CardHeader
        action={<IconButton aria-label="settings"></IconButton>}
        title={props?.newsLinkText}
      />
      <CardMedia
        component="img"
        height="140"
        image={props?.imgSrc}
        title={props?.title}
      />
      <CardContent>
        <Typography gutterBottom variant="subtitle2" component="div">
          {props?.title}
        </Typography>
      </CardContent>
    </Card>
  );
}
