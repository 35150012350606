// import companyprofile from "../../images/Amazon_icon.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tooltip from "@mui/material/Tooltip";
import sunShineIcon from "../../images/Metrics_Sunshine.png";
import cloudyIcon from "../../images/Metrics_Cloudy.png";
import thunderStromIcon from "../../images/Metrics_Thunderstorm.png";
import { CompanyProfile, HistoricalEarnings } from "../../models/StockProfile";
import SbAccordion from "../common/accordion/SbAccordion";
import { CompanyOutlookResponse } from "./model/companyoutlook/company-outlook-response";
import appUtils from "../../shared/utils";
import chartHelpers from "../utils/chart-helpers";
import { useEffect } from "react";
import useApi from "../../useApi";
import useState from "react-usestateref";
import { Ratio } from "./model/ratio";
import SbLineChart from "../common/charts/sbLineChart";
import { Grid } from "@mui/material";
import { WeatherType } from "../utils/constants";
const StockProfileAccordians = (props: any) => {
  const stockProfileInfo = props?.stock as CompanyProfile;
  const historicalEarnings: HistoricalEarnings[] =
    stockProfileInfo?.historicalEarnings || [];

  const companyOutlook = props?.companyOutlook as CompanyOutlookResponse;

  const [ratioData, setRatioData] = useState<Ratio[]>([]);

  // eslint-disable-next-line
  const { sendRequest } = useApi();
  const {
    assetsVsLiabilitiesOptions,
    incomeStatementRevenueOptions,
    incomeStatementNetIncomeOptions,
    createIncomeStatementChart,
    createAssetsVsLiabilitiesChart,
    epsEarningsOptions,
    compareEpsVsEstimatedChart,
  } = chartHelpers();
  const {
    getNumber,
    formatToTwoDecimal,
    convertToInternationalCurrencySystem,
    getYear,
    nullNumberHandler,
  } = appUtils();
  useEffect(() => {
    const getProfit = () => {
      handleGetApiCall<Ratio[]>(
        "/api/stockProfileDetails/ratios/5?symbol=" + stockProfileInfo?.Symbol,
        (data) => {
          setRatioData(data);
        }
      );
    };

    getProfit();

    // eslint-disable-next-line
  }, []);

  const handleGetApiCall = async <T,>(
    path: string,
    callback: (data: T) => void
  ) => {
    try {
      const response = await sendRequest(path);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      callback(data);
    } catch (error) {}
  };

  // Accordion Data
  const stockLongTermReturnAccordianTitle = () => {
    if (stockProfileInfo?.metrics?.returns === WeatherType.thunderstorm) {
      return (
        <Tooltip
          title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
          arrow
        >
          <img src={thunderStromIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.returns === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
        <img src={sunShineIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };
  const areTheyProfitableAccordianTitle = () => {
    if (stockProfileInfo?.metrics?.profitability === WeatherType.sunny) {
      return (
        <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
          <img src={sunShineIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.profitability === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
        arrow
      >
        <img src={thunderStromIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };
  const areTheyGrowingAccordinTitle = () => {
    if (stockProfileInfo?.metrics?.growth === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.growth === WeatherType.thunderstorm) {
      return (
        <Tooltip
          title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
          arrow
        >
          <img src={thunderStromIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
        <img src={sunShineIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };
  const doTheyHaveCashAccordianTitle = () => {
    if (stockProfileInfo?.metrics?.cash === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.cash === WeatherType.thunderstorm) {
      return (
        <Tooltip
          title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
          arrow
        >
          <img src={thunderStromIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
        <img src={sunShineIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };

  const hitMissAccordianTitle = () => {
    if (stockProfileInfo?.metrics?.earnings === WeatherType.sunny) {
      return (
        <Tooltip title="It's all bright and sunny here! Like in Texas." arrow>
          <img src={sunShineIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    if (stockProfileInfo?.metrics?.earnings === WeatherType.cloudy) {
      return (
        <Tooltip
          title="Bit gloomy like Seattle. You never know if its going to clear up or its going to pour."
          arrow
        >
          <img src={cloudyIcon} alt="" width="44" height="44" />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title="Did we just move to Florida? It's severe thunderstorm here. Watchout!"
        arrow
      >
        <img src={thunderStromIcon} alt="" width="44" height="44" />
      </Tooltip>
    );
  };

const stockReturnsContent = () => {
  // Helper function to calculate duration in years
  const calculateDurationInYear = (startDate: Date, endDate: Date): number => {
    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInYears = differenceInTime / (1000 * 3600 * 24 * 365.25);
    return differenceInYears;
  };

  // Get dates first to validate timeframe
  const startDate = new Date(stockProfileInfo?.prices?.begin?.date);
  const endDate = new Date(stockProfileInfo?.prices?.end?.date);
  const numberOfYears = calculateDurationInYear(startDate, endDate);
  
  const priceCAGR = stockProfileInfo?.priceCAGR || 0;
  
  // Ensure valid stock prices before calculation
  const beginPrice = stockProfileInfo?.prices?.begin?.close || 0;
  const endPrice = stockProfileInfo?.prices?.end?.close || 0;
  
  // Calculate simple growth percentage
  const growthPercentage =
    ((endPrice - beginPrice) / beginPrice) * 100;
    
  const displayPercentage =
    numberOfYears < 1 ? growthPercentage : priceCAGR;

  // Determine appropriate timeframe text
  const timeframeDescription = (() => {
    if (numberOfYears < 1 / 12) return "over the past few weeks"; // Less than a month
    if (numberOfYears < 1) return "over the past few months"; // Less than a year
    if (numberOfYears < 5) return `over the past ${Math.floor(numberOfYears)} years`; // 1-4 years
    return "year on year for the past 5 years"; // 5+ years
  })();

  const renderInvestmentExample = () => (
    <>
      , which means a {stockProfileInfo?.currency} 1000 invested in{" "}
      {stockProfileInfo.companyName} in {getYear(5)} would have
      {displayPercentage > 0 ? " grown " : " dropped "} to{" "}
      {stockProfileInfo?.currency}{" "}
      {formatToTwoDecimal(
        (1000 / beginPrice) * endPrice
      )}{" "}
      today
    </>
  );

  return (
    <>
      <h5 className="primary-header-h5">Stock Price</h5>
      <ul>
        <li>
          {stockProfileInfo.companyName}'s stock price
          {displayPercentage > 0 ? " grew " : " dropped "} on an average of
          <span
            className={
              displayPercentage > 0 ? "stock-profit-txt" : "stock-loss-txt"
            }
          >
            {formatToTwoDecimal(displayPercentage)}%
          </span>{" "}
          {timeframeDescription}
          {numberOfYears >= 1 && renderInvestmentExample()}
        </li>
      </ul>
    </>
  );
};

  const isStockProfitableContent = () => {
    return (
      <div>
        <h5 className="primary-header-h5">
          {stockProfileInfo?.companyName}
          {stockProfileInfo?.annualRatios?.netProfitMargin > 0
            ? " is Profitable "
            : " is making a loss "}
        </h5>
        <ul>
          <li>
            {stockProfileInfo?.companyName}{" "}
            {stockProfileInfo?.annualRatios?.netProfitMargin > 0
              ? "made a profit of "
              : "made a loss of "}
            <span
              className={isProfitable() ? "stock-profit-txt" : "stock-loss-txt"}
            >
              {formatToTwoDecimal(
                stockProfileInfo?.annualRatios?.netProfitMargin * 100
              )}
              %
            </span>
             last year
          </li>
          {stockProfileInfo?.annualRatios?.operatingProfitMargin > 0 && (
            <li>
              For every {stockProfileInfo?.currency} of sale{" "}
              {stockProfileInfo?.companyName} makes a{" "}
              {isProfitable() ? "profit" : "loss"} of{" "}
              <span
                className={
                  isProfitable() ? "stock-profit-txt" : "stock-loss-txt"
                }
              >
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(
                  stockProfileInfo?.annualRatios?.operatingProfitMargin
                )}
              </span>
            </li>
          )}
        </ul>
        {stockProfileInfo?.LastDiv > 0 && (
          <h5 className="primary-header-h5">
            <span className="primary-header-h5">Dividends</span>
          </h5>
        )}
        <ul>
          {stockProfileInfo?.LastDiv > 0 && (
            <span>
              <li>
                {stockProfileInfo.companyName} pays cash just for owning their
                stocks. Yes, they call it dividends!
              </li>
              <ul>
                <li>
                  {stockProfileInfo.companyName} paid out a dividend of
                  <span className="stock-profit-txt">
                    {" "}
                    {stockProfileInfo?.currency}{" "}
                    {formatToTwoDecimal(stockProfileInfo?.LastDiv)}{" "}
                  </span>
                  for every stock which is about
                  {stockProfileInfo?.LastDiv && stockProfileInfo?.Price
                    ? formatToTwoDecimal(
                        (stockProfileInfo?.LastDiv / stockProfileInfo?.Price) *
                          100
                      )
                    : 0.0}
                  % of it’s stock value at that time
                </li>
              </ul>
            </span>
          )}

          {stockProfileInfo?.annualRatios?.dividendYield <= 0 && (
            <li>Tough luck! They don’t pay dividends</li>
          )}
        </ul>
      </div>
    );
  };
  const isStockGrowingContent = () => {
    return (
      <div>
        <h5 className="primary-header-h5">
          {stockProfileInfo?.companyName}{" "}
          {companyOutlook?.financialsAnnual &&
          companyOutlook?.financialsAnnual &&
          companyOutlook?.financialsAnnual?.income &&
          nullNumberHandler(
            companyOutlook?.financialsAnnual?.income[0]?.revenue
          ) >
            nullNumberHandler(
              companyOutlook?.financialsAnnual?.income[1]?.revenue
            )
            ? " is growing "
            : " is not growing "}
        </h5>
        <ul>
          <li>
            {stockProfileInfo?.companyName}. generated a revenue of{" "}
            {stockProfileInfo?.currency}{" "}
            {convertToInternationalCurrencySystem(
              stockProfileInfo?.annualStatements?.[0]?.revenue
            )}{" "}
            in {stockProfileInfo?.annualStatements?.[0]?.calendarYear} from{" "}
            {stockProfileInfo?.currency}{" "}
            {convertToInternationalCurrencySystem(
              stockProfileInfo?.annualStatements?.[1]?.revenue
            )}{" "}
            in {stockProfileInfo?.annualStatements?.[1]?.calendarYear} which is{" "}
            {stockProfileInfo?.revenueCAGR?.CAGR1Yr > 0
              ? "an increase of "
              : "a drop of "}
            <span
              className={
                stockProfileInfo?.revenueCAGR?.CAGR1Yr > 0
                  ? "stock-profit-txt"
                  : "stock-loss-txt"
              }
            >
              {formatToTwoDecimal(
                (nullNumberHandler(
                  companyOutlook?.financialsAnnual?.income?.[0]?.revenue -
                    nullNumberHandler(
                      companyOutlook?.financialsAnnual?.income?.[1]?.revenue
                    )
                ) /
                  ((nullNumberHandler(
                    companyOutlook?.financialsAnnual?.income?.[0]?.revenue
                  ) +
                    nullNumberHandler(
                      companyOutlook?.financialsAnnual?.income?.[1]?.revenue
                    )) /
                    2)) *
                  100
              )}
              %
            </span>
          </li>
          {stockProfileInfo?.revenueCAGR?.CAGR2Yr > 0 &&
            stockProfileInfo?.revenueCAGR?.CAGR4Yr > 0 && (
              <li>
                Also, their revenue grew by an average of {" "}
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR2Yr)}%
                </span>{" "}
                over 2 years and
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR4Yr)}%{" "}
                </span>
                 over 4 years
              </li>
            )}
          {stockProfileInfo?.revenueCAGR?.CAGR2Yr <= 0 &&
            stockProfileInfo?.revenueCAGR?.CAGR4Yr > 0 && (
              <li>
                Also, their revenue dropped by an average of{" "}
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR2Yr)}%{" "}
                </span>
                over 2 years but grew on an average by {" "}
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR4Yr)}%{" "}
                </span>
                 over 4 years
              </li>
            )}
          {stockProfileInfo?.revenueCAGR?.CAGR2Yr > 0 &&
            stockProfileInfo?.revenueCAGR?.CAGR4Yr <= 0 && (
              <li>
                Also, their revenue grew by an average of
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR2Yr)}%{" "}
                </span>
                over 2 years but dropped by an average of 
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR4Yr)}%{" "}
                </span>
                 over 4 years
              </li>
            )}
          {stockProfileInfo?.revenueCAGR?.CAGR2Yr <= 0 &&
            stockProfileInfo?.revenueCAGR?.CAGR4Yr <= 0 && (
              <li>
                Also, their revenue dropped by an average of
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR2Yr)}%{" "}
                </span>
                over 2 years and
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR4Yr)}%{" "}
                </span>
                 over 4 years
              </li>
            )}
        </ul>
        <h5 className="primary-header-h5">Profits</h5>
        <ul>
          <li>
            {stockProfileInfo?.companyName}'s profits
            {stockProfileInfo?.netIncomeCAGR?.CAGR1Yr > 0 ? (
              <span>
                {" grew by "}
                <label className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR1Yr)}{" "}
                  %
                </label>
              </span>
            ) : (
              <span>
                {" dropped by "}
                <label className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR1Yr)}
                  %
                </label>{" "}
              </span>
            )}{" "}
            in the last year
          </li>
          {stockProfileInfo?.netIncomeCAGR?.CAGR2Yr > 0 &&
            stockProfileInfo?.netIncomeCAGR?.CAGR4Yr > 0 && (
              <li>
                Also, their profits grew by an average of{" "}
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR2Yr)}
                  %
                </span>{" "}
                over 2 years and{" "}
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR4Yr)}
                  %
                </span>{" "}
                 over 4 years
              </li>
            )}

          {stockProfileInfo?.netIncomeCAGR?.CAGR2Yr <= 0 &&
            stockProfileInfo?.netIncomeCAGR?.CAGR4Yr > 0 && (
              <li>
                Also, their profits dropped by an average of{" "}
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR2Yr)}
                  %{" "}
                </span>{" "}
                over 2 years but grew on an average by {" "}
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR4Yr)}
                  %  over 4 years
                </span>
              </li>
            )}
          {stockProfileInfo?.netIncomeCAGR?.CAGR2Yr > 0 &&
            stockProfileInfo?.netIncomeCAGR?.CAGR4Yr <= 0 && (
              <li>
                Also, their profits grew by an average of{" "}
                <span className={"stock-profit-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR2Yr)}
                  %
                </span>{" "}
                over 2 years but dropped by an average of {" "}
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR4Yr)}
                  %
                </span>{" "}
                 over 4 years
              </li>
            )}
          {stockProfileInfo?.netIncomeCAGR?.CAGR2Yr <= 0 &&
            stockProfileInfo?.netIncomeCAGR?.CAGR4Yr <= 0 && (
              <li>
                Also, their profits dropped by an average of{" "}
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.netIncomeCAGR?.CAGR2Yr)}
                  %
                </span>{" "}
                over 2 years and{" "}
                <span className={"stock-loss-txt"}>
                  {formatToTwoDecimal(stockProfileInfo?.revenueCAGR?.CAGR4Yr)}%
                </span>{" "}
                 over 4 years
              </li>
            )}
        </ul>
        {getIncomeStatementChart()}
      </div>
    );
  };
const doTheyHaveCashContent = () => {
  return (
    <div>
      <h5 className="primary-header-h5">
        {stockProfileInfo?.annualRatios?.currentRatio >= 1
          ? "Short Term: Yes"
          : stockProfileInfo?.annualRatios?.currentRatio < 1
          ? "Short Term: No"
          : "Short Term: On the Line"}
      </h5>

      {/* Cash section */}
      <div>
        {stockProfileInfo?.annualRatios?.currentRatio >= 1 && (
          <div>
            <p>
              Yes! {stockProfileInfo.companyName} is doing fine — they’ve got
              enough cash to cover their short-term debts. For every {stockProfileInfo?.currency} 1 they owe, they’ve got {" "} 
              <span className={"stock-profit-txt"}>
                <strong style={{ color: "purple" }}>{stockProfileInfo?.currency}
                  {formatToTwoDecimal(stockProfileInfo?.annualRatios?.currentRatio)}
                </strong>
              </span>{" "}
              in the bank to pay it off.
            </p>
            <p>
              <strong>What does this mean?</strong> They can handle their bills without sweating
              too much. Check the trend below to see if their cash situation is
              getting better or worse.
            </p>

          </div>
        )}

        {stockProfileInfo?.annualRatios?.currentRatio < 1 && (
          <div>
            <p>
              Nope! {stockProfileInfo.companyName} might be in a bit of a pinch
              — they don’t have enough cash to cover their short-term debts. For
              every {stockProfileInfo?.currency}1 they owe, they only have{" "}
              <span className={"stock-loss-txt"}>
                <strong style={{ color: "red" }}>
                  {stockProfileInfo?.currency}{formatToTwoDecimal(stockProfileInfo?.annualRatios?.currentRatio)}
                </strong>
              </span>{" "}
              to pay it off.
            </p>
            <p>
              <strong>What does this mean?</strong> They might need to hustle to find more cash.
              Check the trend below to see if their cash situation is improving
              or getting shakier.
            </p>
          </div>
        )}

        {stockProfileInfo?.annualRatios?.currentRatio === 1 && (
          <div>
            <p>
              {stockProfileInfo.companyName} is right on the line — they have
              just enough cash to cover their short-term debts. For every {stockProfileInfo?.currency}1 they owe, they’ve got exactly {stockProfileInfo?.currency}1 to pay it off.
            </p>
            <p>
              What does this mean? They’re balanced, but there’s no cushion.
              It’s like living paycheck to paycheck. See the trend below to know
              if they’re staying stable or at risk of slipping.
            </p>
          </div>
        )}
      </div>
                 {/* Asset-Liability Chart */}
             <div>{getAssetLiabilityChart()}</div>
      
      {/* Debt to Equity Ratio section */}
      <hr /> {/* Adding a break for readability */}
      
      <div>
            
      <h5 className="primary-header-h5">
         "The Debt Game"
      </h5>
        {stockProfileInfo?.annualRatios?.debtEquityRatio > 1 && (
          <div>
            <p>
              {stockProfileInfo.companyName} is borrowing more than they’re
              making. About{" "}
              <span className={"stock-loss-txt"}>
                <strong style={{ color: "red" }}>
                  {formatToTwoDecimal(
                    (stockProfileInfo?.annualRatios?.debtEquityRatio /
                      (1 + stockProfileInfo?.annualRatios?.debtEquityRatio)) *
                      100
                  )}
                  %
                </strong>
              </span>{" "}
              of their funds are coming from debt, and only{" "}
              <span className={"stock-profit-txt"}>
                <strong style={{ color: "purple" }}>
                  {formatToTwoDecimal(
                    100 -
                      (stockProfileInfo?.annualRatios?.debtEquityRatio /
                        (1 + stockProfileInfo?.annualRatios?.debtEquityRatio)) *
                        100
                  )}
                  %
                </strong>
              </span>{" "}
              is coming from selling stocks.
            </p>
          </div>
        )}

        {stockProfileInfo?.annualRatios?.debtEquityRatio === 1 && (
          <div>
            <p>
              {stockProfileInfo.companyName} is balancing borrowed money and
              stock sales perfectly. They’re using an equal mix of loans and the
              cash they’ve raised by selling shares. About{" "}
              <span className={"stock-profit-txt"}>
                <strong style={{ color: "purple" }}>
                  {formatToTwoDecimal(
                    (stockProfileInfo?.annualRatios?.debtEquityRatio /
                      (1 + stockProfileInfo?.annualRatios?.debtEquityRatio)) *
                      100
                  )}
                  %
                </strong>
              </span>{" "}
              of their funding is from debt, and{" "}
              <span className={"stock-profit-txt"}>
                <strong style={{ color: "purple" }}>
                  {formatToTwoDecimal(
                    100 -
                      (stockProfileInfo?.annualRatios?.debtEquityRatio /
                        (1 + stockProfileInfo?.annualRatios?.debtEquityRatio)) *
                        100
                  )}
                  %
                </strong>
              </span>{" "}
              is from stocks.
            </p>
          </div>
        )}

        {stockProfileInfo?.annualRatios?.debtEquityRatio < 1 && stockProfileInfo?.annualRatios?.debtEquityRatio >= 0 && (
          <div>
            <p>
              {stockProfileInfo.companyName} is killing it by using more of
              their own money from stock sales and relying less on loans. Only{" "}
              <span className={"stock-profit-txt"}>
                <strong style={{ color: "purple" }}>
                  {formatToTwoDecimal(
                    (stockProfileInfo?.annualRatios?.debtEquityRatio /
                      (1 + stockProfileInfo?.annualRatios?.debtEquityRatio)) *
                      100
                  )}
                  %
                </strong>
              </span>{" "}
              of their funding is from borrowed money, and the rest (
              <span className={"stock-profit-txt"}>
                <strong style={{ color: "purple" }}>
                  {formatToTwoDecimal(
                    100 -
                      (stockProfileInfo?.annualRatios?.debtEquityRatio /
                        (1 + stockProfileInfo?.annualRatios?.debtEquityRatio)) *
                        100
                  )}
                  %
                </strong>
              </span>
              ) comes from stock sales.
            </p>
          </div>
        )}

        {/* Handling negative Debt-to-Equity ratio */}
        {stockProfileInfo?.annualRatios?.debtEquityRatio < 0 && (
          <div>
            <p>
              {stockProfileInfo.companyName} has negative equity, meaning their debts are bigger than what their shareholders have invested.. Their liabilities are{" "}
              <span className={"stock-loss-txt"}>
                <strong style={{ color: "red" }}>
                  {formatToTwoDecimal(
                    Math.abs(
                      100 -
                        (stockProfileInfo?.annualRatios?.debtEquityRatio /
                          (1 + stockProfileInfo?.annualRatios?.debtEquityRatio)) *
                          100
                    )
                  )}
                  %
                </strong>
              </span>{" "}
              more than their shareholder equity.
            </p>
          </div>
        )}
      </div>

      {/* Assets vs. Liabilities section */}
      <hr /> {/* Another break for readability */}
      <div>
              <h5 className="primary-header-h5">
        {getNumber(stockProfileInfo?.score?.totalAssets) >
          getNumber(stockProfileInfo?.score?.totalLiabilities) 
          ? "Long Term: Yes"
          : getNumber(stockProfileInfo?.score?.totalAssets) <
          getNumber(stockProfileInfo?.score?.totalLiabilities)
          ? "Long Term: No"
          : "Long Term: On The Line"}
      </h5>
        
        {getNumber(stockProfileInfo?.score?.totalAssets) >
          getNumber(stockProfileInfo?.score?.totalLiabilities) && (
          <div>
            <p>
              {stockProfileInfo.companyName} is in the green — they own more
              stuff (
              <span className={"stock-profit-txt"}>
                <strong style={{ color: "purple" }}>
                  {stockProfileInfo?.currency} {" "} 
                  {convertToInternationalCurrencySystem(
                    stockProfileInfo?.score?.totalAssets
                  )}
                </strong>
              </span>
              ) than they owe (
              <span className={"stock-profit-txt"}>
                <strong style={{ color: "purple" }}>
                  {stockProfileInfo?.currency} {" "} 
                  {convertToInternationalCurrencySystem(
                    stockProfileInfo?.score?.totalLiabilities
                  )}
                </strong>
              </span>
              ).
            </p>
          </div>
        )}

        {getNumber(stockProfileInfo?.score?.totalAssets) <=
          getNumber(stockProfileInfo?.score?.totalLiabilities) && (
          <div>
            <p>
              {stockProfileInfo.companyName} is in the red — they owe more (
              <span className={"stock-loss-txt"}>
                <strong style={{ color: "red" }}>
                  {stockProfileInfo?.currency} {" "} 
                  {convertToInternationalCurrencySystem(
                    stockProfileInfo?.score?.totalLiabilities
                  )}
                </strong>
              </span>
              ) than they own (
              <span className={"stock-loss-txt"}>
                <strong style={{ color: "red" }}>
                  {stockProfileInfo?.currency} {" "} 
                  {convertToInternationalCurrencySystem(
                    stockProfileInfo?.score?.totalAssets
                  )}
                </strong>
              </span>
              )
            </p>
          </div>
        )}
      </div>
        
      
</div>
 
   );
};


  const earningHitMissRatioContent = () => {
    const topValidEPS = getTopValidEps();
    return (
      <div>
        {topValidEPS.actualEarningResult >= topValidEPS.estimatedEarning && (
          <ul>
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              Bullseye - It's a Hit!
            </span>
            <li>
              They make a profit (earnings per share or eps) of{" "}
              <span className="stock-profit-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(topValidEPS.actualEarningResult)}
              </span>{" "}
              for every share owned by their shareholders
            </li>
            <li>
              Their earnings per share last quarter Hit their target{" "}
              <span className="stock-profit-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(topValidEPS.actualEarningResult)}
              </span>{" "}
              vs{" "}
              <span className="stock-profit-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(topValidEPS.estimatedEarning)}
              </span>{" "}
            </li>
          </ul>
        )}
        {topValidEPS.actualEarningResult < topValidEPS.estimatedEarning && (
          <ul>
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              Off-target - It's a Miss!
            </span>
            <li>
              They make a profit (earnings per share or eps) of{" "}
              <span className="stock-loss-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(topValidEPS.actualEarningResult)}
              </span>{" "}
              for every share owned by their shareholders
            </li>
            <li>
              However, it was lesser than what they expected to achieve{" "}
              <span className="stock-profit-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(topValidEPS.actualEarningResult)}
              </span>{" "}
              vs{" "}
              <span className="stock-profit-txt">
                {stockProfileInfo?.currency}{" "}
                {formatToTwoDecimal(topValidEPS.estimatedEarning)}
              </span>{" "}
              last quarter{" "}
            </li>
          </ul>
        )}
        {getEpsEarningsChart()}
      </div>
    );
  };

  const getTopValidEps = (): HistoricalEarnings => {
    const defaultVal: HistoricalEarnings = {
      actualEarningResult: 0,
      estimatedEarning: 0,
      date: "",
      symbol: "",
    };
    if (!historicalEarnings?.length) {
      return defaultVal;
    }
    // Added below condition x.eps != 0 as we are getting eps value as 0 in some cases instead of null
    let index = historicalEarnings.findIndex(
      (x: { actualEarningResult: number | null }) =>
        x.actualEarningResult !== null && x.actualEarningResult !== 0
    );

    return index === -1
      ? defaultVal
      : stockProfileInfo!.historicalEarnings![index];
  };

  const isProfitable = (): boolean =>
    nullNumberHandler(stockProfileInfo?.annualRatios?.netProfitMargin) > 0;
  
  const getIncomeStatementChart = () => {
    if (
      !companyOutlook?.financialsAnnual?.income ||
      companyOutlook?.financialsAnnual?.income?.length === 0
    ) {
      return <div></div>;
    }
    const resultData = createIncomeStatementChart(
      companyOutlook?.financialsAnnual?.income
    );
    return (
      <Grid container spacing={2}>
        <Grid item={true} xs={12} md={6} lg={6}>
          <SbLineChart
            data={resultData?.revenueData}
            options={incomeStatementRevenueOptions()}
            width=""
            height="400px"
            displayTimeMachineIcon={false}
          />
        </Grid>
        <Grid item={true} xs={12} md={6} lg={6}>
          <SbLineChart
            data={resultData?.incomeData}
            options={incomeStatementNetIncomeOptions()}
            width=""
            height="400px"
            displayTimeMachineIcon={false}
          />
        </Grid>
      </Grid>
    );
  };
  const getAssetLiabilityChart = () => {
    if (!ratioData || ratioData?.length === 0) {
      return <div></div>;
    }
    const assetData = createAssetsVsLiabilitiesChart(ratioData);

    return (
      <div>
        <p style={{ left: "20%" }}> <strong>Short-term Assets vs Liabilities</strong></p>
        <p>
          <small>
            (Note: If Current Ratio is greater than 1 then it indicates Assets are greater liabilities; else Liabilities
            are greater than Assets)
          </small>
        </p>
        <SbLineChart
          data={assetData}
          options={assetsVsLiabilitiesOptions()}
          width=""
          height="400px"
          displayTimeMachineIcon={false}
        />
      </div>
    );
  };
  const getEpsEarningsChart = () => {
    // console.log("historicalEarningsData", historicalEarningsData);
    if (!historicalEarnings?.length) {
      return <div></div>;
    }

    const chartData = compareEpsVsEstimatedChart(historicalEarnings);
    return (
      <SbLineChart
        data={chartData}
        options={epsEarningsOptions()}
        width="100%"
        height="300px"
        displayTimeMachineIcon={false}
      />
    );
  };
  const accordionItems = [
    {
      title: "Is the stock giving long term returns?",
      content: stockReturnsContent(),
      icons: [stockLongTermReturnAccordianTitle()],
    },
    {
      title: "Are they profitable?",
      content: isStockProfitableContent(),
      icons: [areTheyProfitableAccordianTitle()],
    },
    {
      title: "Are they growing?",
      content: isStockGrowingContent(),
      icons: [areTheyGrowingAccordinTitle()],
    },
    {
      title: "Do they have cash?",
      content: doTheyHaveCashContent(),
      icons: [doTheyHaveCashAccordianTitle()],
    },
    {
      title: "Earnings Hit or Miss",
      content: earningHitMissRatioContent(),
      icons: [hitMissAccordianTitle()],
    },
  ];

  return <SbAccordion items={accordionItems} />;
};

export default StockProfileAccordians;
