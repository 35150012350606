import { useState, useEffect } from "react";
import styles from "./MainContent.module.css";
import { Grid } from "@mui/material";
import StockCard from "../ui/StockCard";
import useApi from "../../useApi";
import { CompanyProfile } from "../../models/StockProfile";
function MyFavourites(searchText: any) {
  //eslint-disable-next-line
  const { sendRequest } = useApi();
  const [profiles, setProfiles] = useState<CompanyProfile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState("Discover Stocks, ETFs, Funds");
  let categoryKeyFilter: string = "";
  let sectoryKeyFilter: string = "";
  useEffect(() => {
    setPageTitle("Your List of Favorites");
    //eslint-disable-next-line
    fetchData();
    //eslint-disable-next-line
  }, []);
  const handleGetApiCall = async <T,>(
    path: string,
    callback: (data: T) => void
  ) => {
    try {
      setIsLoading(true);
      //eslint-disable-next-line
      const response = await sendRequest(path);
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      callback(data);
    } catch (error) {}
  };
  function unFavouriteStockItem(stock: CompanyProfile) {
    const favoriteItems = profiles.filter((x) => x?.Symbol !== stock?.Symbol);
    setProfiles(favoriteItems);
  }
  const fetchData = () => {
    //eslint-disable-next-line
    let favUrl = `/api/Favorites`;
    if (categoryKeyFilter) {
      favUrl = `${favUrl}${categoryKeyFilter}`;
    }
    if (sectoryKeyFilter) {
      favUrl = `${favUrl}${sectoryKeyFilter}`;
    }
    handleGetApiCall<CompanyProfile[]>(favUrl, (data) => {
      if (data?.length > 0) {
        data.forEach((x) => {
          x.metrics = updateMetrics(x?.metrics);
        });
      }
      setProfiles(data);
    });
    //eslint-disable-next-line
  };
  const updateMetrics = (metrics: any) => {
    const objKv = Object.keys(metrics);
    objKv.forEach((x) => {
      if (!metrics[x]) {
        delete metrics[x];
      }
    });
    return metrics;
  };

  if (!isLoading && !profiles.length) {
    return (
      <div className="alert alert-warning alert-dismissible" role="alert">
        You do not have a favourite yet.
      </div>
    );
  }
  return (
    <>
      <Grid container className={styles.filterSection}>
        <Grid item xs={12}>
          <h2 className={styles.heading}>{pageTitle}</h2>
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.cardContainer}>
        {profiles.map((stock: CompanyProfile, i) => (
          <StockCard
            stock={stock}
            key={i}
            unFavouriteStock={unFavouriteStockItem}
          />
        ))}
      </Grid>
    </>
  );
}

export default MyFavourites;
