import React, { useEffect, useState } from "react";
import { useLoading } from "./loading-context";
import { Box, CircularProgress } from "@mui/material";

const Spinner: React.FC = () => {
  const { isLoading, loadingCount } = useLoading();
  const [showLoader, setShowLoader] = useState<boolean>();

  useEffect(() => {
    setShowLoader(isLoading || loadingCount > 0);
  }, [isLoading, loadingCount]);

  if (!showLoader) return null;

  return (
    <Box
      sx={{ display: "flex" }}
      style={{
        display: "flex",
        paddingTop: "60vh",
        zIndex: 20000,
        position: "absolute",
        left: "55vw",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
