export default function CountryFlagIcon(props: any) {
  return (
    <img
      loading="lazy"
      className={props?.imgClass}
      style={{ width: props?.imageWidth }}
      srcSet={`https://flagcdn.com/w40/${props?.code?.toLowerCase()}.png 2x`}
      src={`https://flagcdn.com/w20/${props?.code?.toLowerCase()}.png`}
      alt=""
    />
  );
}
