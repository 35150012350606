import CustomCard from "../common/customCards";
import Slider from "react-slick";
import useApi from "../../useApi";
import { useEffect } from "react";
import useState from "react-usestateref";
import { StockNews } from "./model/companyoutlook/stock-news";
import { Typography } from "@mui/material";
export default function StockProfileNewsSlider(props: any) {
  const { sendRequest } = useApi();
  // eslint-disable-next-line
  const [news, setNews, newsRef] = useState<StockNews[]>([]);
  useEffect(() => {
    const handleGetApiCall = async <T,>(
      path: string,
      callback: (data: T) => void
    ) => {
      try {
        const response = await sendRequest(path);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        callback(data);
      } catch (error) {}
    };
    const getStockNews = () => {
      handleGetApiCall<StockNews[]>(
        "/api/stockProfileDetails/news?symbol=" + props?.symbol,
        (data) => {
          setNews(data);
        }
      );
    };
    getStockNews(); // eslint-disable-next-line
  }, []);
  var latestNews = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    arrows: true,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {" "}
      {newsRef?.current?.length > 0 && (
        <div>
          {" "}
          <Typography variant="h5" className="mb-2">
            Latest news about {props?.companyName}.
          </Typography>
          <div className="primary-txt-16 mb-3">
            Just what everyone’s talking about
          </div>
          <Slider {...latestNews}>
            {newsRef?.current?.map((item, index) => (
              <div key={index}>
                <CustomCard
                  imgSrc={item?.image}
                  title={item?.title}
                  description={item?.text}
                  newsLink={item?.url}
                  newsLinkText={item?.site}
                ></CustomCard>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
}
