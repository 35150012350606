import React from "react";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Link,
} from "@mui/material";
import Logo from "../../assets/logo.png";
import CloseIcon from "@mui/icons-material/Close";
import SbAccordion from "../../components/common/accordion/SbAccordion";
import { ApplicationRoutes } from "../../constants/application-routes";

const FaqPage = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // Accordion content
  const faqAccordionItems = [
    {
      title: "1. What is Stockbruh?",
      content: (
        <div>
          Stockbruh is an innovative platform designed to simplify stock market
          analysis for beginner investors. By eliminating complex technical
          jargon, Stockbruh makes it easier to understand the fundamental
          aspects of stocks and ETFs.
        </div>
      ),
      icons: [],
    },
    {
      title: "2. Is the website complete or still in development?",
      content: (
        <div>
          While Stockbruh currently offers a robust range of features, it
          remains in beta. We are continuously enhancing the platform with new
          features and improvements based on user feedback and market trends.
        </div>
      ),
      icons: [],
    },
    {
      title: "3. Where does Stockbruh get its data?",
      content: (
        <div>
          Stockbruh sources its financial data from Financial Modeling Prep, a
          trusted third-party provider. More details can be found{" "}
          <Link
            href="https://site.financialmodelingprep.com/developer/docs"
            underline="hover"
            target="_blank"
            rel="noreferrer"
          >
            here
          </Link>
          .
        </div>
      ),
      icons: [],
    },
    {
      title: "4. What do the Sunny, Cloudy, and Thunderstorm icons represent?",
      content: (
        <div>
          <h5 className="primary-header-h5 mb-3">
            These icons represent the fundamental analysis outcomes for stocks
            and ETFs:
          </h5>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium">Sunny :</span> Indicates positive
              metrics, suggesting a favorable outlook.
            </li>
            <li>
              <span className="fw-medium">Cloudy :</span> Denotes average
              metrics, neither strongly positive nor negative.
            </li>
            <li>
              <span className="fw-medium">Thunderstorm :</span> Points to
              below-average metrics, indicating potential concerns.
            </li>
          </ul>
          <h5 className="primary-header-h5">Additional Information for ETFs</h5>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium">Returns: </span>
              ETFs are evaluated based on their long-term return potential,
              categorized by the growth in investment value over five years and
              calculated using the Compound Annual Growth Rate (CAGR). Depending
              on the CAGR, ETFs are marked as:
            </li>
            <li>
              <span className="fw-medium">Sunshine :</span> CAGR ≥ 8%
            </li>
            <li>
              <span className="fw-medium">Cloudy :</span>{" "}
              <span> 1% ≤ CAGR 8%</span>
            </li>
            <li>
              <span className="fw-medium">Thunderstorm:</span> CAGR {"<"} 1%
            </li>
          </ul>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium">Dividends: </span>
              ETFs that distribute dividends are assessed on their yield and
              recent payment history. Their ratings are:
            </li>
            <li>
              <span className="fw-medium">Sunshine : </span> Dividend yield ≥ 1%
            </li>
            <li>
              <span className="fw-medium">Cloudy : </span>{" "}
              <span>
                {" "}
                0% {"<"} Dividend yield {"<"} 1%
              </span>
            </li>
            <li>
              <span className="fw-medium">Thunderstorm : </span> No dividends
              paid in the last 12 months or a dividend yield of 0%
            </li>
          </ul>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium">Fees : </span>
              ETFs are also rated based on their expense ratios. These fees,
              known as expense ratios, are annual charges based on the total
              assets under management, meant to cover the operating expenses of
              the fund.
            </li>
            <li>
              <span className="fw-medium">Sunny : </span>Expense ratio {"<"}{" "}
              0.5%
            </li>
            <li>
              <span className="fw-medium">Cloudy : </span>{" "}
              <span>0.5% ≤ Expense ratio ≤ 1.5%</span>
            </li>
            <li>
              <span className="fw-medium">Thunderstorm : </span>Expense ratio{" "}
              {">"} 1.5%
            </li>
          </ul>

          <h5 className="primary-header-h5 mb-3">
            Additional Information for Evaluating Stocks
          </h5>
          <h6 className="primary-header-h6 mb-2">1. Returns</h6>
          <div className="primary-txt-16 mb-1">
            Stock returns are assessed based on the Compound Annual Growth Rate
            (CAGR) over a five-year period:
          </div>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium">Sunny :</span> Stock Price CAGR ≥ 8%
              (Indicates strong growth, well above industry average).
            </li>
            <li>
              <span className="fw-medium">Cloudy :</span> 1% ≤ Stock Price CAGR{" "}
              {"<"} 8% (Represents moderate growth, roughly around the industry
              average)
            </li>
            <li>
              <span className="fw-medium">Thunderstorm :</span> Stock Price CAGR{" "}
              {"<"} 1% (Signifies very low or negative growth, indicating
              potential issues or stagnation).
            </li>
          </ul>

          <h6 className="primary-header-h6 mb-2">2. Profitability</h6>
          <div className="primary-txt-16 mb-1">
            Profitability is measured using metrics like net profit margin:
          </div>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium">Sunny :</span> Net Profit Margin is
              Positive and High (Shows that the company is highly profitable
              relative to its revenue).
            </li>
            <li>
              <span className="fw-medium">Thunderstorm :</span> Net Profit
              Margin is Negative (The company is losing money, which could
              indicate financial troubles or a challenging operational
              environment).
            </li>
          </ul>

          <h6 className="primary-header-h6 mb-2">3. Growth Metrics</h6>
          <div className="primary-txt-16 mb-1">
            Growth is considered based on the year-over-year change in revenue
            and profits:
          </div>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium">Sunny :</span> Both Revenue and
              Profits show positive growth for the past year (Indicates robust
              expansion and profitability).
            </li>
            <li>
              <span className="fw-medium">Cloudy :</span> Mixed results; if only
              one metric (revenue or profit) is positive, or growth is
              negligible (Suggests inconsistent or slow growth).
            </li>
            <li>
              <span className="fw-medium">Thunderstorm :</span> Both Revenue and
              Profits show negative growth for the past year (Indicates
              contraction and potential operational or market challenges).
            </li>
          </ul>

          <h6 className="primary-header-h6 mb-2">4. Earnings</h6>
          <div className="primary-txt-16 mb-1">
            Earnings performance is evaluated based on earnings per share (EPS)
            compared to estimated EPS:
          </div>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium">Sunny :</span> eps ≥ epsEstimated and
              eps {">"} 1 (Earnings exceed expectations and are positive).
            </li>
            <li>
              <span className="fw-medium">Cloudy :</span> eps {"<"} epsEstimated
              and eps {">"} 1 (Earnings did not meet expectations but are still
              positive). eps {">"} epsEstimated and eps {"<"} 1 (Earnings exceed
              low expectations but are less than 1).
            </li>
            <li>
              <span className="fw-medium">Thunderstorm :</span> Both eps {"<"}{" "}
              epsEstimated and eps {"<"} 0 (Earnings are below expectations and
              negative).
            </li>
          </ul>

          <h6 className="primary-header-h6 mb-2">5. Cash Situation</h6>
          <div className="primary-txt-16 mb-1">
            Cash situation is analyzed through current ratio, debt-to-equity
            ratio, and total assets versus total liabilities:
          </div>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium">Sunny :</span> Current ratio {">"} 1,
              Debt to Equity Ratio {"<"} 1, and Total Assets {">"} Total
              Liabilities (Indicates strong liquidity and financial health).
            </li>
            <li>
              <span className="fw-medium">Cloudy :</span> When conditions do not
              exactly match those of Sunny or Thunderstorm scenarios (Moderate
              financial status).
            </li>
            <li>
              <span className="fw-medium">Thunderstorm :</span> Current ratio{" "}
              {"<"} 1 (Indicates poor liquidity and potential financial
              instability).
            </li>
          </ul>
        </div>
      ),
      icons: [],
    },
    {
      title: "5. How accurate is the data?",
      content: (
        <div>
          Data from Financial Modeling Prep is rigorously tested and sanitized.
          Calculations made by Stockbruh undergo extensive testing to ensure
          reasonable accuracy. However, users might encounter minor delays or
          omissions, which can be reported via{" "}
          <Link
            href="mailto:support@stockbruh.com"
            underline="hover"
            rel="noreferrer"
          >
            support@stockbruh.com
          </Link>
          .
        </div>
      ),
      icons: [],
    },
    {
      title: "6. What stock exchanges does Stockbruh cover?",
      content: (
        <div>
          Stockbruh lists stocks from global exchanges, including the NYSE,
          NASDAQ, AMEX, EURONEXT, TSX, and NSE.
        </div>
      ),
      icons: [],
    },
    {
      title: "7. Are ETFs and mutual funds listed on Stockbruh?",
      content: (
        <div>
          Yes, Stockbruh provides listings for both ETFs and mutual funds,
          catering to a variety of investment preferences.
        </div>
      ),
      icons: [],
    },
    {
      title: "8. What is the significance of the rocket icon in stock charts?",
      content: (
        <div>
          The rocket icon adds a visual flair to our stock charts. It has no
          analytical significance; its purpose is to enliven the graphical
          presentation. We frequently update these icons to keep the interface
          fresh and engaging.
        </div>
      ),
      icons: [],
    },
    {
      title: "9. Is Stockbruh a financial advisor?",
      content: (
        <div>
          Stockbruh is not a licensed financial advisor or a fiduciary. Our
          platform is designed to simplify financial data and present it in an
          easily understandable format to assist users in making their own
          investment decisions. The information provided by Stockbruh is for
          educational and informational purposes only and should not be
          considered as financial advice.
        </div>
      ),
      icons: [],
    },
    {
      title:
        "10. Should I base my investment decisions solely on the information from Stockbruh?",
      content: (
        <div>
          While Stockbruh strives to provide accurate and timely information,
          all investment decisions should be made considering multiple sources
          of information and, if possible, with the consultation of a financial
          advisor. Investors should conduct their own research or consult a
          professional advisor to ensure that any investment decisions align
          with their financial goals and risk tolerance.
        </div>
      ),
      icons: [],
    },
    {
      title:
        "11. What should I consider before making investment decisions based on Stockbruh’s data?",
      content: (
        <div>
          <div className="primary-txt-16 mb-1">
            Investors should consider the following before making decisions
            based on data from Stockbruh:
          </div>
          <ul className="list-unstyled">
            <li>
              <span className="fw-medium">Risk Tolerance : </span>
              Understanding your own risk tolerance is crucial. Each investment
              carries its own level of risk, which should align with your
              personal financial situation and investment goals.
            </li>
            <li>
              <span className="fw-medium">Market Conditions : </span>
              Financial markets are influenced by a wide array of factors
              including economic indicators, global events, and market
              sentiment. It’s important to consider the broader economic and
              financial landscape.
            </li>
            <li>
              <span className="fw-medium">Diversification : </span> Relying
              solely on one tool or data point for investment decisions is
              risky. Diversifying your sources of information can provide a more
              rounded view of potential investments.
            </li>
          </ul>
        </div>
      ),
      icons: [],
    },
    {
      title:
        "12. What disclaimer does Stockbruh provide regarding its services?",
      content: (
        <div>
          <span className="fw-medium">Disclaimer : </span> The data and graphics
          presented on Stockbruh are derived from sources believed to be
          reliable and in good faith, but we make no representations or
          warranties, express or implied, as to the accuracy, completeness, or
          timeliness of the information provided. Stockbruh shall not have
          liability for any damages, losses, or expenses of any kind arising out
          of or in connection with the use of its data. Our content is provided
          without any guarantees, conditions, or warranties as to its accuracy.
        </div>
      ),
      icons: [],
    },
  ];
  return (
    <>
      <Container fixed>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component="section" sx={{ py: 2 }}>
              <a href={ApplicationRoutes.Home}>
                <img src={Logo} alt="Logo" width={233} height={68} />
              </a>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h1 className="primary-header mb-4">
            Frequently Asked Questions (FAQ) for Stockbruh
          </h1>
          <div className="mb-3">
            <SbAccordion items={faqAccordionItems} />
          </div>
        </Grid>
      </Container>
      <Grid>
        <Box component="footer" className="sb-footer my-3">
          <div style={{ color: "#ccc" }}>
            Data provided by Financial Modeling Prep
          </div>
          <ul className="list-inline mb-0">
            <li className="list-inline-item">
              <a href="/privacy" style={{ color: "#ccc" }}>
                Privacy Policy
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/terms" style={{ color: "#ccc" }}>
                Terms of Use
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/" style={{ color: "#ccc" }}>
                Disclaimer
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/faq" style={{ color: "#ccc" }}>
                FAQ
              </a>
            </li>
            <li className="list-inline-item">
              <span
                className="cursor-pointer"
                style={{ color: "#ccc" }}
                onClick={handleClickOpen}
              >
                Contact Us
              </span>
            </li>
          </ul>
        </Box>
      </Grid>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth={"xs"}
        open={open}
      >
        <DialogContent dividers>
          <div className="d-flex mb-3 justify-content-between align-items-center">
            <div className="primary-header-h4">Contact Us</div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <h5 className="primary-txt-16 mb-3">
              If you have any questions, You can contact us:
            </h5>
            <ul>
              <li>
                By email:{" "}
                <a href="mailto:support@stockbruh.com">support@stockbruh.com</a>
              </li>
            </ul>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default FaqPage;
